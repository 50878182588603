import React, { Suspense, lazy, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import PublicLayout from "../layouts/PublicLayout";
import RequireAuth from "../pages/RequireAuth";
import ErrorBoundary from "./errorBoundary";
import { ROUTE_PRODUCT_PATH } from "../constants/routePath";
import { toolsData } from "../pages/HomePage/Tools/constants/index.js";

// Lazy load components (including new ones)
const HomePage = lazy(() => import("../pages/HomePage"));

//Discover
const Instagram = lazy(() => import("../pages/HomePage/Discover/Instagram"));
const Twitter = lazy(() => import("../pages/HomePage/Discover/Twitter"));
const TikTok = lazy(() => import("../pages/HomePage/Discover/TikTok"));

//Create
const TShirt = lazy(() => import("../pages/HomePage/Create/TShirt"));

const Tools = lazy(() => import("../pages/HomePage/Tools"));
const Auth = lazy(() => import("../pages/Auth"));
const Login = lazy(() => import("../pages/Auth/components/Login"));
const Register = lazy(() => import("../pages/Auth/components/Register"));
const ForgotPassword = lazy(() => import("../pages/Auth/components/ForgotPassword"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Income = lazy(() => import("../pages/Income"));
const TransactionDetails = lazy(() => import("../pages/Income/TransactionDetails"));
const Customers = lazy(() => import("../pages/Customers"));
const CustomerDetails = lazy(() => import("../pages/Customers/CustomerDetails"));
const MyStore = lazy(() => import("../pages/MyStore"));
const Products = lazy(() => import("../pages/MyStore/components/Products"));
const Settings = lazy(() => import("../pages/Settings"));
const Store = lazy(() => import("../pages/StorePage"));
const Checkout = lazy(() => import("../pages/UserCheckout"));
const SuccessPage = lazy(() => import("../pages/UserCheckout/Success"));

// Product-related lazy imports
const UrlProduct = lazy(() => import("../pages/MyStore/components/Products/Url"));
const DigitalProduct = lazy(() => import("../pages/MyStore/components/Products/Digital"));
const CoachingCallProduct = lazy(() => import("../pages/MyStore/components/Products/CoachingCall"));
const CustomProduct = lazy(() => import("../pages/MyStore/components/Products/Custom"));
const EmailProduct = lazy(() => import("../pages/MyStore/components/Products/Email"));
const VideoProduct = lazy(() => import("../pages/MyStore/components/Products/Video"));
const ReferralProduct = lazy(() => import("../pages/MyStore/components/Products/Referral"));

// New lazy imports for missing components
const Onboarding = lazy(() => import("../pages/Onboarding"));
const OnboardingSuccess = lazy(() => import("../pages/OnboardingSuccess"));
const Shop = lazy(() => import("../pages/MyStore/components/Shop"));
const Design = lazy(() => import("../pages/MyStore/components/Design"));
const UserProfile = lazy(() => import("../pages/MyStore/components/UserProfile"));
const Billing = lazy(() => import("../pages/Settings/components/Billing"));
const Payments = lazy(() => import("../pages/Settings/components/Payments"));
const Notifications = lazy(() => import("../pages/Settings/components/Notifications"));
const Profile = lazy(() => import("../pages/Settings/components/Profile"));
const Referrals = lazy(() => import("../pages/Referrals"));

//Configurator
const ClothingConfigurator = lazy(() => import("../pages/HomePage/Create/ClothingConfigurator"));

// Edit product lazy imports
const EditUrlProduct = lazy(() => import("../pages/MyStore/components/Products/Url/editUrl"));
const EditEmailProduct = lazy(() => import("../pages/MyStore/components/Products/Email/editEmail"));
const EditVideoProduct = lazy(() => import("../pages/MyStore/components/Products/Video/editVideo"));
const EditReferralProduct = lazy(() => import("../pages/MyStore/components/Products/Referral/editReferral"));

const isAdminDomain = window.location.hostname === 'admin.sparkle.store';
//const isAdminDomain = 'admin.sparkle.store';

export const ROUTES = isAdminDomain ? [
    // Admin routes (admin.sparkle.store)
    {
        path: '/',
        element: <AuthLayout />,
        children: [
            { index: true, element: <Suspense fallback={null}><Login /></Suspense> },
            { path: 'auth/login', element: <Suspense fallback={null}><Login /></Suspense> },
            { path: 'auth/register', element: <Suspense fallback={null}><Register /></Suspense> },
            { path: 'auth/forgot', element: <Suspense fallback={null}><ForgotPassword /></Suspense> },
            { path: 'auth', element: <Suspense fallback={null}><Auth /></Suspense> },
        ],
    },
    {
        path: '/',
        element: <RequireAuth />,
        children: [
            { path: 'dashboard', element: <Suspense fallback={null}><Dashboard /></Suspense> },
            { path: 'income', element: <Suspense fallback={null}><Income /></Suspense> },
            { path: 'income/:transactionId', element: <Suspense fallback={null}><TransactionDetails /></Suspense> },
            { path: 'customers', element: <Suspense fallback={null}><Customers /></Suspense> },
            { path: 'customer/:customerId', element: <Suspense fallback={null}><CustomerDetails /></Suspense> },
            { path: 'store', element: <Suspense fallback={null}><MyStore /></Suspense> },
            { path: 'products', element: <Suspense fallback={null}><Products /></Suspense> },
            { path: 'settings', element: <Suspense fallback={null}><Settings /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.URL_PRODUCT, element: <Suspense fallback={null}><UrlProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.DIGITAL_PRODUCT, element: <Suspense fallback={null}><DigitalProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.COACHING_CALL, element: <Suspense fallback={null}><CoachingCallProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.CUSTOM_PRODUCT, element: <Suspense fallback={null}><CustomProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.EMAIL_PRODUCT, element: <Suspense fallback={null}><EmailProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.VIDEO_PRODUCT, element: <Suspense fallback={null}><VideoProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.REFERRAL_PRODUCT, element: <Suspense fallback={null}><ReferralProduct /></Suspense> },
            // New routes
            { path: 'onboarding', element: <Suspense fallback={null}><Onboarding /></Suspense> },
            { path: 'onboarding-success', element: <Suspense fallback={null}><OnboardingSuccess /></Suspense> },
            { path: 'shop', element: <Suspense fallback={null}><Shop /></Suspense> },
            { path: 'design', element: <Suspense fallback={null}><Design /></Suspense> },
            { path: 'profile', element: <Suspense fallback={null}><UserProfile /></Suspense> },
            { path: 'settings/billing', element: <Suspense fallback={null}><Billing /></Suspense> },
            { path: 'settings/profile', element: <Suspense fallback={null}><Profile /></Suspense> },
            { path: 'settings/payments', element: <Suspense fallback={null}><Payments /></Suspense> },
            { path: 'settings/notifications', element: <Suspense fallback={null}><Notifications /></Suspense> },
            { path: 'referrals', element: <Suspense fallback={null}><Referrals /></Suspense> },
            // Edit product routes
            { path: ROUTE_PRODUCT_PATH.EDIT_URL_PRODUCT, element: <Suspense fallback={null}><EditUrlProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.EDIT_EMAIL_PRODUCT, element: <Suspense fallback={null}><EditEmailProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.EDIT_VIDEO_PRODUCT, element: <Suspense fallback={null}><EditVideoProduct /></Suspense> },
            { path: ROUTE_PRODUCT_PATH.EDIT_REFERRAL_PRODUCT, element: <Suspense fallback={null}><EditReferralProduct /></Suspense> },
        ],
    },
] : [
    {
        path: '/',
        element: <PublicLayout />,
        children: [
            { index: true, element: <Suspense fallback={null}><HomePage /></Suspense> },
            //tools
            { path: 'tools', element: <Suspense fallback={null}><Tools /></Suspense> },
            ...toolsData.map(tool => ({
                path: `tools/${tool.link.split('/').pop()}`,
                element: <Suspense fallback={null}>{React.createElement(lazy(() => import(`../pages/HomePage/Tools/pages/${tool.file.replace(/\s+/g, '')}`)
                ))}</Suspense>,
            })),

            //creator
            { path: 'creator/link-in-bio-for-instagram', element: <Suspense fallback={null}><Instagram /></Suspense> },
            { path: 'creator/link-in-bio-for-twitter', element: <Suspense fallback={null}><Twitter /></Suspense> },
            { path: 'creator/link-in-bio-for-tiktok', element: <Suspense fallback={null}><TikTok /></Suspense> },

            //Create
            { path: 'create/t-shirts', element: <Suspense fallback={null}><TShirt /></Suspense> },
            { path: 'create/t-shirts/customize', element: <Suspense fallback={null}><ClothingConfigurator /></Suspense> },

            //Store Routes
            { path: ':storeName', element: <Suspense fallback={null}><Store /></Suspense> },
            { path: ':storeName/checkout/:productSlug', element: <Suspense fallback={null}><Checkout /></Suspense> },
            { path: ':storeName/checkout/success/:transactionId', element: <Suspense fallback={null}><SuccessPage /></Suspense> },
        ],
    },
];

const router = createBrowserRouter(ROUTES);

export default function App() {
    useEffect(() => {
        // Preload important routes
        Dashboard.preload();
        MyStore.preload();
        Products.preload();
        Store.preload();
    }, []);

    return (
        <ErrorBoundary>
            <RouterProvider router={router} />
        </ErrorBoundary>
    );
}