import Axios from "axios";

export const axios = Axios.create();
// Request interceptor for API calls
axios.interceptors.request.use(
    (config) => {
        try {
            const token = localStorage.getItem("SparkleAuthToken");

            if (token) {
                config.headers["x-access-token"] = token;
            }
        } catch (e) {
            // localStorage is not available
        }

        return config;
    },
    (error) => Promise.reject(error),
);
