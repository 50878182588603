import styled from "styled-components";
import { Row, Col, Flex, Image } from "antd";
import { COLORS } from "../../constants/colors";

export const StyledRow = styled(Row)`
    min-height: 100dvh;
`;

export const GlobalStyle = styled.div`
  body {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
  }
`;

export const StyledContent = styled(Flex)`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin: 0 auto;
    width: fit-content;
`;

export const StyledImage = styled(Image)`
    && {
        margin: 0 auto 80px auto;
        width: fit-content;
        display: flex;
    }
`;

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  flex: 1;
`;

export const StyledWrapper = styled(Col)`
    background: ${COLORS.$blueShades98};
    padding-top: 135px;
`;

export const StyledSquare = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    border-top: 300px solid ${COLORS.$blueShades92};
    border-right: 300px solid transparent;
`;

export const StyledCol = styled(Col)`
    form {
        width: 100%;
    }
`;

export const StyledHeader = styled.div`
    height: 96px;
    display: ruby-text;
    align-items: center;
    margin: auto;
    gap: 4px;
    padding-top: 25px;
    margin-bottom: 80px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: ${COLORS.$black};

    @media (max-width: 1024px) {
        height: 72px;
    }
`;
