export const toolsData = [
    // {
    //     title: "Free Logo Maker",
    //     description: "Design a professional, custom logo in minutes with our AI-powered free logo maker. No design skills needed.",
    //     subtitle: "Create your unique brand logo",
    //     icon: "fa-paint-brush",
    //     link: "/free-logo-maker"
    // },
    {
        title: "Business Name Generator",
        description: "Discover the perfect business name with our AI-driven name generator. Get creative, memorable, and available domain suggestions.",
        subtitle: "Find your ideal business name",
        icon: "fa-lightbulb",
        file: "Business Name Generator",
        link: "tools/business-name-generator"
    },
    {
        title: "Domain Name Generator",
        description: "Find and register the perfect domain name for your business. Get SEO-friendly suggestions and instant availability checks.",
        subtitle: "Secure your online presence",
        icon: "fa-globe",
        file: "Domain Name Generator",
        link: "tools/domain-name-generator"
    },
    {
        title: "AI Image Generator",
        description: "Create AI-generated images for your business or personal use in seconds. Transform your ideas into stunning visuals with our powerful and easy-to-use tool.",
        subtitle: "Create AI Images now",
        icon: "fa-image",
        file: "Art Generator",
        link: "tools/image-generator"
    },
    {
        title: "Profit Margin Calculator",
        description: "Determine optimal pricing and boost profitability with our easy-to-use profit margin calculator. Maximize your returns.",
        subtitle: "Calculate profit margins",
        icon: "fa-percentage",
        file: "Profit Margin Calculator",
        link: "tools/profit-margin-calculator"
    },
    {
        title: "Business Loan Calculator",
        description: "Estimate loan payments and terms with our user-friendly business loan calculator. Make informed financial decisions.",
        subtitle: "Calculate loan options",
        icon: "fa-calculator",
        file: "Business Loan Calculator",
        link: "tools/business-loan-calculator"
    },
    // {
    //     title: "Slogan Maker",
    //     description: "Craft catchy, memorable slogans for your brand with our AI-powered slogan generator. Stand out from the competition.",
    //     subtitle: "Generate your brand slogan",
    //     icon: "fa-comment",
    //     link: "/slogan-maker"
    // },

    // {
    //     title: "Sparkle: Link in Bio Tool",
    //     description: "Transform your social media bio into a revenue-generating landing page with our customizable link in bio tool.",
    //     subtitle: "Monetize your social presence",
    //     icon: "fa-link",
    //     link: "/"
    // },
    // {
    //     title: "WHOIS Domain Lookup",
    //     description: "Instantly access comprehensive domain registration data for any website. Verify ownership and availability.",
    //     subtitle: "Research domain information",
    //     icon: "fa-search",
    //     link: "/whois-lookup"
    // },
    // {
    //     title: "Pay Stub Generator",
    //     description: "Create professional, customizable pay stubs in seconds with our cloud-based generator. Streamline your payroll process.",
    //     subtitle: "Generate accurate pay stubs",
    //     icon: "fa-file-invoice-dollar",
    //     link: "/pay-stub-generator"
    // },
    // {
    //     title: "QR Code Generator",
    //     description: "Create custom QR codes for your business. Boost engagement and track scans with our free, easy-to-use tool.",
    //     subtitle: "Design your QR code",
    //     icon: "fa-qrcode",
    //     link: "/qr-code-generator"
    // },
    // {
    //     title: "Terms and Conditions Generator",
    //     description: "Protect your business with legally-sound terms and conditions. Customize for your needs in minutes.",
    //     subtitle: "Create legal terms",
    //     icon: "fa-file-contract",
    //     link: "/terms-and-conditions-generator"
    // },
    // {
    //     title: "Business Card Maker",
    //     description: "Design professional business cards for free. Choose from stunning templates and customize to match your brand.",
    //     subtitle: "Create your business card",
    //     icon: "fa-address-card",
    //     link: "/business-card-maker"
    // },
    // {
    //     title: "Invoice Generator",
    //     description: "Create professional, branded invoices in seconds. Our cloud-based tool simplifies billing and improves cash flow.",
    //     subtitle: "Generate custom invoices",
    //     icon: "fa-file-invoice",
    //     link: "/invoice-generator"
    // },
    // {
    //     title: "Purchase Order Template",
    //     description: "Streamline your procurement process with our customizable purchase order templates. Professional and easy to use.",
    //     subtitle: "Create purchase orders",
    //     icon: "fa-file-signature",
    //     link: "/purchase-order-template"
    // },
    // {
    //     title: "Privacy Policy Generator",
    //     description: "Create a comprehensive, GDPR-compliant privacy policy for your website or app in minutes. Protect your business and users.",
    //     subtitle: "Generate privacy policy",
    //     icon: "fa-shield-alt",
    //     link: "/privacy-policy-generator"
    // },
    // {
    //     title: "Shipping Label Template",
    //     description: "Create and print professional shipping labels for free. Streamline your order fulfillment process with ease.",
    //     subtitle: "Design shipping labels",
    //     icon: "fa-shipping-fast",
    //     link: "/shipping-label-template"
    // },
    // {
    //     title: "Refund Policy Generator",
    //     description: "Build trust with customers using a clear, fair refund policy. Customize to fit your business needs in minutes.",
    //     subtitle: "Create refund policy",
    //     icon: "fa-undo",
    //     link: "/refund-policy-generator"
    // },
    // {
    //     title: "Bill of Lading Template",
    //     description: "Simplify shipping documentation with our customizable bill of lading template. Ensure smooth logistics operations.",
    //     subtitle: "Generate bill of lading",
    //     icon: "fa-file-alt",
    //     link: "/bill-of-lading-template"
    // },
    // {
    //     title: "Barcode Generator",
    //     description: "Create unique, scannable barcodes for your products. Improve inventory management and streamline operations.",
    //     subtitle: "Generate custom barcodes",
    //     icon: "fa-barcode",
    //     link: "/barcode-generator"
    // },
    // {
    //     title: "Image Resizer",
    //     description: "Optimize your product photos with our free image resizer. Enhance visual appeal and improve site performance.",
    //     subtitle: "Resize images professionally",
    //     icon: "fa-compress-arrows-alt",
    //     link: "/image-resizer"
    // },

    // {
    //     title: "CPM Calculator",
    //     description: "Optimize your ad campaigns with our CPM calculator. Measure and improve your advertising ROI effortlessly.",
    //     subtitle: "Calculate ad performance",
    //     icon: "fa-chart-line",
    //     link: "/cpm-calculator"
    // },
    // {
    //     title: "Discount Calculator",
    //     description: "Quickly calculate discounts, savings, and final prices. Optimize your pricing strategy and promotional offers.",
    //     subtitle: "Calculate discounts easily",
    //     icon: "fa-tags",
    //     link: "/discount-calculator"
    // }
];