import { createGlobalStyle } from "styled-components";
import { COLORS } from "./constants/colors";

export const GlobalStyles = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        -webkit-tap-highlight-color: transparent;
        -webkit-backface-visibility: hidden;
    }

    html,
    body,
    #root {
        position: relative;
        overflow: hidden;
        min-width: 320px;
        background: #f3f3f1;
        height: 100%;
    }

    html,
    body {
        overflow: hidden;
    }

    #root {
        overflow-y: scroll;
    }

    body {
        margin: 0;
        font-family: 'Open Sans', sans-serif; /* Updated font-family */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 14px;
    }

    a {
        text-decoration: none;
        color: ${COLORS.$mainBlue};

        &:hover {
            color: inherit;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        &.ant-typography {
            margin-bottom: 0;
        }
    }

    a {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
    }
`;
