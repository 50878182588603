import React from 'react';
import { Outlet } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { GlobalStyles } from './GlobalStyles'; 

function App() {
    return (
        <HelmetProvider>
            <GlobalStyles /> 
            <Outlet />
        </HelmetProvider>
    );
}

export default App;
