import React from "react";
import { StyledRow, StyledHeader, StyledCol, StyledImage } from "./styled";
import { isDesktop } from "react-device-detect";
import { Outlet } from "react-router-dom";
import logo from "../../assets/images/sparklelogo.png";
import { observer } from "mobx-react";

const Index = observer(() => {
    return (
        <StyledRow>
            <StyledCol span={isDesktop ? 24 : 24}>
                <StyledHeader>
                    <StyledImage preview={false} src={logo} />
                </StyledHeader>
                <Outlet />
            </StyledCol>
        </StyledRow>
    );
});

export default Index;
