import React from "react";
import { Outlet } from "react-router-dom";
import { observer } from "mobx-react";
import { StyledRow, StyledCol, LayoutWrapper, ContentWrapper, GlobalStyle } from "./styled";
import { isDesktop } from "react-device-detect";

const PublicLayout = observer(() => {
    return (
        <>
            <GlobalStyle />
            <LayoutWrapper>
                <ContentWrapper>
                    <StyledRow>
                        <StyledCol span={isDesktop ? 24 : 24}>
                            <Outlet />
                        </StyledCol>
                    </StyledRow>
                </ContentWrapper>
            </LayoutWrapper>
        </>
    );
});

export default PublicLayout;