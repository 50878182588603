export const ROUTE_PATH = {
    DASHBOARD: "/dashboard",
    HOME: "/",
    TOOLS: "/tools",
    INSTAGRAM: "/creator/link-in-bio-for-instagram",
    TWITTER: "/creator/link-in-bio-for-twitter",
    TIKTOK: "/creator/link-in-bio-for-tiktok",
    LINKEDIN: "/creator/link-in-bio-for-linkedin",
    TSHIRT: "/create/t-shirts",
    TSHIRTCUSTOMIZER: "/create/t-shirts/customize",
    INCOME: "/income",
    SETTINGS: "/settings",
    PROFILE: "/settings/profile",
    USERPROFILE: "/profile",
    CUSTOMERS: "/customers",
    BILLING: "/settings/billing",
    PAYMENTS: "/settings/payments",
    NOTIFICATIONS: "/settings/notifications",
    MYSTORE: "/store",
    SHOP: "/shop",
    DESIGN: "/design",
    PRODUCTS: "/products",
    ONBOARDING: "/onboarding",
    LOGIN: "/auth/login",
    REGISTER: "/auth/register",
    FORGOT_PASSWORD: "/auth/forgot",
    REFERRALS: "/referrals"
};

export const ROUTE_PRODUCT_PATH = {
    URL_PRODUCT: "/products/add-products/url",
    EDIT_URL_PRODUCT: "/products/edit/url/:id",
    DIGITAL_PRODUCT: "/add-products/digital-product",
    COACHING_CALL: "/add-products/coaching-call",
    CUSTOM_PRODUCT: "/add-products/custom-product",
    EMAIL_PRODUCT: "/add-products/email-product",
    EDIT_EMAIL_PRODUCT: "/products/edit/email/:id",
    VIDEO_PRODUCT: "/add-products/video-product",
    EDIT_VIDEO_PRODUCT: "/products/edit/video/:id",
    REFERRAL_PRODUCT: "/add-products/referral-product",
    EDIT_REFERRAL_PRODUCT: "/products/edit/referral/:id",
};
