export const COLORS = {
    $black: "#000",
    $blackOpacity50: "rgba(0,0,0,.5)",
    $white: "#fff",
    $mainDark: "#000929",
    $mainBlue: "#005EB8",
    $mainBlueHover: "#00408F",
    $lightBlue: "#0288D1",
    $blueShades98: "#F7FBFD",
    $blueShades96: "#EFF4FB",
    $blueShades94: "#E6F0F9",
    $blueShades92: "#DEE8F7",
    $blueShades90: "#D6E2F5",
    $greyScale50: "#F9FAFB",
    $greyScale100: "#F4F4F6",
    $greyScale200: "#E5E6EB",
    $greyScale300: "#D3D5DA",
    $greyScale400: "#9EA3AE",
    $greyScale500: "#6C727F",
    $greyScale600: "#4D5461",
    $greyScale700: "#394150",
    $greyScale800: "#212936",
    $greyScale900: "#0B0A0F",
    $blue: '#007bff',
    $purple: '#6f42c1',
    $green: '#28a745',
};
