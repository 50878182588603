import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";
import { HelmetProvider } from 'react-helmet-async';
import { GlobalStyles } from "./GlobalStyles";
import { ROUTES } from "./constants/routes";
import { StoreContext } from "./hooks/storeContext";
import store from "./_store/index";

const router = createBrowserRouter(ROUTES);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <StoreContext.Provider value={store}>
        <ConfigProvider>
            <HelmetProvider>
                <GlobalStyles />
                <RouterProvider router={router}>
                    <App />
                </RouterProvider>
            </HelmetProvider>
        </ConfigProvider>
    </StoreContext.Provider>,
);