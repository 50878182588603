import React, { useEffect } from "react";
import { useStore } from "../../hooks/storeContext";
import { observer } from "mobx-react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../constants/routePath";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from "antd";

const RequireAuth = observer(() => {
    const navigate = useNavigate();
    const { authStore } = useStore();
    const token = localStorage.getItem("SparkleAuthToken");

    useEffect(() => {
        (async () => {
            if (!token) {
                navigate(ROUTE_PATH.LOGIN, {
                    replace: true,
                });
                return;
            }

            if (authStore.me) {
                return;
            }

            await authStore.whoami();
        })();
    }, [authStore.me]);

    if (!token) {
        return <Navigate to={ROUTE_PATH.LOGIN} replace />;
    }

    if (!authStore.me) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
        )
    }

    return <Outlet />;
});

export default RequireAuth;
